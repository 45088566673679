<template>
  <div>
   <ServerError v-if="ServerError" /> <PageLoader  :active="appLoading" />

    <v-dialog v-model="suspendDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
            >
              Do you want to suspend this officer ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="suspendUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="suspendDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
      <v-dialog v-model="activateDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
            >
              Do you want to activate this officer ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="activateUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="activateDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  
    <v-layout wrap justify-start>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm12 >
              <v-layout wrap justify-start>
                   <v-flex xs12 sm2 md1 text-left align-self-center>
              <v-avatar color="#FFF9C4" size="70">
                <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-flex>
                <v-flex xs6 sm4 md2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.fullName }} 
                      <span v-if="!storage.fullName">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm4 md2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Designation</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.designation }}
                      <span v-if="!storage.designation">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm4 md2>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Phone</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.phone }}
                      <span v-if="!storage.phone">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6 sm4 md3>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Email</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.email }}
                      <span v-if="!storage.email">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                  <v-flex xs12 sm6 md2>
              <v-layout wrap  >
                <v-flex align-self-center xs12 v-if="storage.status=='active'"  pt-3 pt-xs-0 >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="suspendDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Suspend </span>
                  </v-btn>
                </v-flex>
                  <v-flex align-self-center xs12 v-if="storage.status=='inactive'"  pt-3 pt-xs-0 >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="activateDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Activate </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
              </v-layout>
            </v-flex>
     
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
      suspendDialogue: false,
      activateDialogue: false,
    };
  },
  methods: {
    editUser() {
      this.$emit("stepper", {
        type: "Edit",
        editUser: true,
        userData: this.storage,
      });
    },
    suspendUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/official/added/account/suspend",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.suspendDialogue = false;
            this.$emit("stepper", {
              type: "Suspend",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    activateUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/official/added/account/activate",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.activateDialogue = false;
            this.$emit("stepper", {
              type: "Activate",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>