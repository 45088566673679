<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading"> Add New Officer </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-form @submit.prevent="validateInput">
          <v-layout wrap justify-start>
            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Name</span>
              <v-text-field
                ref="fullName"
                color="#FDCE48"
                class="formClass"
                :rules="[rules.required ]"
                placeholder="Name"
                dense
                v-model="fullName"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Phone No</span>
              <v-text-field
                ref="phone"
                color="#FDCE48"
                class="formClass"
                placeholder="Phone No"
                type="number"
                dense
                v-model="phone"
                :error-messages="msg_phone"
                :rules="[rules.required, rules.min]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Email Id</span>
              <v-text-field
                ref="email"
                class="formClass"
                :rules="[rules.required ]"
                color="#FDCE48"
                placeholder="Email"
                dense
                v-model="email"
                :error-messages="msg_email"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 text-left px-2>
              <span class="itemValue">Designation</span>
              <v-text-field
                ref="designation"
                color="#FDCE48"
                class="formClass"
                :rules="[rules.required ]"
                placeholder="Designation"
                dense
                v-model="designation"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs6 sm4 xl3 px-2>
              <v-btn
                block
                tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
                @click="closeDialoge"
                class="itemValue"
              >
                Cancel
              </v-btn>
            </v-flex>
            <v-flex xs6 sm4 xl3 px-2>
              <v-btn
                block
                tile
                :color="appColor"
                light
                :ripple="false"
                depressed
                @click="validateInput"
                class="itemValue"
              >
                <span style="color: #fff">Add Officer</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      fullName: null,
      phone: null,
      email: null,
      designation: null,
      designations: [
        { name: "Range Officer", value: "rangeOfficer" },
        { name: "Dy.Range Officer", value: "dyRangeOfficer" },
      ],
      rules: {
        required: (value) => !!value || "Required",
        min: (value) => value.length == 10 || "Invalid Phone",
      },
      formHasErrors: false,
      msg_email: null,
      msg_phone: null,
    };
  },
  computed: {
    form() {
      return {
        phone: this.phone,
        email: this.email,
        designation: this.designation,
        fullName: this.fullName,
      };
    },
  },

  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    validateInput() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        if (!this.$refs[f].validate(true)) {
          this.msg = "Please Provide Valid " + f;
          this.showSnackBar = true;
          this.formHasErrors = true;
          return;
        }
      });
      if (this.formHasErrors == false) this.addUser();
    },
    addUser() {
      var officerData = {};
      officerData["fullName"] = this.fullName;
      officerData["phone"] = this.phone;
      officerData["email"] = this.email;
      officerData["designation"] = this.designation;
      // officerData["role"] = this.officerType;
      axios({
        method: "post",
        url: "/official/add/account ",
        data: officerData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "new Officer Created";
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "Add",
              getData: true,
              addUser: false,
            });
            
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>